import {
  SubHeader,
  useBoundingClientRect,
  useWindowResize,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, ReactNode, memo, useMemo, useRef } from 'react';
import styles from './Layout.module.scss';

const cx = classnames.bind(styles);

type Props = {
  children: ReactNode;
  title?: string;
  showHeaderBorderBottom?: boolean;
  headerRight?: ReactNode;
  hideContentPadding?: boolean;
};

export const Layout: FC<Props> = memo(
  ({
    children,
    title,
    showHeaderBorderBottom,
    headerRight,
    hideContentPadding = false,
  }) => {
    const ref = useRef<HTMLDivElement>(null);
    const { innerHeight } = useWindowResize();
    const { top } = useBoundingClientRect(ref);
    const contentContainerStyle = useMemo(
      () => ({
        height: innerHeight - top,
      }),
      [innerHeight, top]
    );
    return (
      <div className={cx(styles['container'])}>
        <div
          className={cx(styles['top'], {
            [styles['show-border-bottom']]: showHeaderBorderBottom,
          })}
        >
          <div>
            <SubHeader title={title || ''} />
          </div>
          {headerRight}
        </div>
        <div
          ref={ref}
          className={cx(styles['bottom'], {
            [styles['content-padding']]: !hideContentPadding,
          })}
          style={contentContainerStyle}
        >
          <main className={cx(styles['content'])}>{children}</main>
        </div>
      </div>
    );
  }
);
Layout.displayName = 'Layout';
