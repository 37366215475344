export enum Workflow {
  /** 承認ごと */
  everyStep = 'every_step',
  /** 最終承認時のみ */
  onlyFinalApproval = 'only_final_approval',
  /** 通知しない */
  notNotify = 'not_notify',
}
export type workflow = {
  isActive: boolean;
  onlyFinalApproval: boolean;
};

export type approvableReport = {
  isActive: boolean;
};

export type reportTimeline = {
  isActive: boolean;
};

export type MailNotificationSettingFormType = {
  workflow: 'every_step' | 'only_final_approval' | 'not_notify';
  approvableReport: boolean;
  reportTimeline: boolean;
};

export type MailNotificationSettingRequest = {
  workflow: workflow;
  approvableReport: approvableReport;
  reportTimeline: reportTimeline;
};
