import { ErrorBoundary } from '@/components/Rollbar';
import { DrawerCloseProvider } from '@/context/Drawer';
import { useBusinessDocumentsSearch } from '@/context/services/business_document/BusinessDocument.service';
import { BusinessDocumentsSearchRefetchProvider } from '@/context/services/business_document/refetchContext';
import { useBusinessDocumentsColumns } from '@/features/business_documents/List/Table/Columns/column';
import { Drawer } from '@/features/business_documents/List/Table/Drawer';
import { useBusinessDocumentList } from '@/features/business_documents/List/Table/hook';
import {
  BusinessDocumentColumn,
  BusinessDocumentSearchQuery,
} from '@/features/business_documents/List/type';
import { UploadForm } from '@/features/business_documents/UploadForm';
import { useVisibilityToggle } from '@/features/business_documents/hooks';
import { useTranslation } from '@/i18n';
import {
  ButtonV2,
  ModernPaginationProps,
  Table,
  TableProps,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, Suspense, memo, useCallback, useMemo } from 'react';
import styles from './Table.module.scss';

const cx = classnames.bind(styles);

export type BusinessDocumentTableProps = Omit<
  TableProps<BusinessDocumentColumn>,
  'columns' | 'pagination' | 'emptyType'
>;

type Props = {
  pagination: ModernPaginationProps;
  table: BusinessDocumentTableProps;
  tableAction?: React.ReactNode;
};

const BusinessDocumentTable: FC<Props> = ({
  pagination,
  table,
  tableAction,
}) => {
  const { t } = useTranslation();
  const columns = useBusinessDocumentsColumns();

  return (
    <div className={cx(styles['container'])}>
      <Table
        emptyType='file'
        {...table}
        legacy={false}
        key={pagination.currentPage}
        emptyText={t('business_document_list_empty_message')}
        columns={columns}
        pagination={pagination}
        tableAction={tableAction}
        testId='business-document-table'
      />
    </div>
  );
};
BusinessDocumentTable.displayName = 'BusinessDocumentTable';

export const BusinessDocumentTableLoading: FC = memo(() => {
  const pagination = useMemo(
    () => ({
      currentPage: 1,
      maxPage: 1,
      perPage: 1,
      maxRecord: 0,
    }),
    []
  );
  const tableProps = useMemo(() => ({ data: [], loading: true }), []);
  return <BusinessDocumentTable table={tableProps} pagination={pagination} />;
});
BusinessDocumentTableLoading.displayName = 'BusinessDocumentTableLoading';

type ErrorBusinessDocumentTableProps = {
  error: Error | null;
};

export const BusinessDocumentTableError: FC<ErrorBusinessDocumentTableProps> =
  memo(() => {
    const pagination = useMemo(
      () => ({
        currentPage: 1,
        maxPage: 1,
        perPage: 1,
        maxRecord: 0,
      }),
      []
    );
    const tableProps = useMemo(
      () => ({ data: [], loading: false, emptyType: 'error' }),
      []
    );
    return <BusinessDocumentTable table={tableProps} pagination={pagination} />;
  });
BusinessDocumentTableError.displayName = 'BusinessDocumentTableError';

export type BusinessDocumentTableViewProps = {
  searchQuery: BusinessDocumentSearchQuery;
  onSearch: (query: BusinessDocumentSearchQuery) => void;
};

const InnerTable: FC<BusinessDocumentTableViewProps> = memo(
  ({ searchQuery: _searchQuery, onSearch }) => {
    const { t } = useTranslation();
    const { visible, handleOpen, handleClose } = useVisibilityToggle();
    const { onDrawerClose, onDrawerOpen, selectedId } =
      useBusinessDocumentList();

    const query = useMemo(() => {
      const { page, ...searchQuery } = _searchQuery;
      return { query: { ...searchQuery }, page };
    }, [_searchQuery]);

    const {
      data,
      pagination: _pagination,
      refetch,
    } = useBusinessDocumentsSearch(query);

    const onPageChange = useCallback(
      (page: number) => {
        onSearch({ page });
      },
      [onSearch]
    );

    const pagination = useMemo(() => {
      return {
        ..._pagination,
        onChange: onPageChange,
      };
    }, [_pagination, onPageChange]);

    const table: BusinessDocumentTableProps = useMemo(() => {
      return {
        data,
        onRow: (data) => {
          return {
            onClick: () => {
              onDrawerOpen(data.id);
            },
          };
        },
      };
    }, [data, onDrawerOpen]);
    return (
      <BusinessDocumentsSearchRefetchProvider value={refetch}>
        <DrawerCloseProvider value={onDrawerClose}>
          <BusinessDocumentTable
            pagination={pagination}
            table={table}
            tableAction={
              <ButtonV2
                size='sm'
                onClick={() => handleOpen()}
                testId='bulk-upload-bussiness-document'
              >
                {t('upload_business_document_button')}
              </ButtonV2>
            }
          />
          <UploadForm open={visible} onClose={handleClose} />
          <Drawer
            selectedId={selectedId}
            open={!!selectedId}
            onClose={onDrawerClose}
          />
        </DrawerCloseProvider>
      </BusinessDocumentsSearchRefetchProvider>
    );
  }
);
InnerTable.displayName = 'InnerTable';

export const BusinessDocumentTableView: FC<BusinessDocumentTableViewProps> =
  memo((props) => {
    return (
      <ErrorBoundary fallback={BusinessDocumentTableError}>
        <Suspense fallback={<BusinessDocumentTableLoading />}>
          <InnerTable {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  });
BusinessDocumentTableView.displayName = 'BusinessDocumentTableView';
