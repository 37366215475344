import { DataTableCurrencyRate } from '@/features/foreign_currency_settings/components/Detail/CurrencyRateTable/type';
import { useTranslation } from '@/i18n';
import { ButtonGroup, ButtonV2 } from '@moneyforward/ap-frontend-components';
import type { ColumnType } from 'antd/es/table/interface';

export const useColumns = (
  onClickEdit: (value: DataTableCurrencyRate) => void,
  onClickShowDeleteModal: (value: DataTableCurrencyRate) => void,
  isDisabled: boolean
): ColumnType<DataTableCurrencyRate>[] => {
  const { t } = useTranslation();
  return [
    {
      title: t('foreign_currency_settings.detail.data_table.header.rate'),
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: t('foreign_currency_settings.detail.data_table.header.period'),
      dataIndex: 'period',
      key: 'period',
    },
    {
      title: t('foreign_currency_settings.detail.data_table.header.updated_at'),
      dataIndex: 'updated_at',
      key: 'updated_at',
    },
    {
      title: '',
      key: 'actions',
      width: '150px',
      render(value: DataTableCurrencyRate) {
        return (
          <ButtonGroup>
            <ButtonV2
              isSecondary
              onClick={() => onClickEdit(value)}
              isDisabled={isDisabled}
            >
              {t('edit')}
            </ButtonV2>
            <ButtonV2
              isSecondary
              color='danger'
              onClick={() => onClickShowDeleteModal(value)}
              isDisabled={isDisabled}
            >
              {t('delete')}
            </ButtonV2>
          </ButtonGroup>
        );
      },
    },
  ];
};
