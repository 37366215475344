import { useErrorInlineNotification } from '@/components/ErrorInlineNotification';
import { useNotificationSettingInfoSuspense } from '@/context/services/notification_setting/notification_setting.service';
import { MailNotificationSettingForm } from '@/features/notification_setting/components/MailNotificationSetting/MailNotificationSettingForm';
import { MailNotificationSettingInfo } from '@/features/notification_setting/components/MailNotificationSetting/MailNotificationSettingInfo';
import { useTranslation } from '@/i18n';
import {
  ButtonGroup,
  ButtonV2,
  Heading,
  IconApproval,
  InlineNotification,
  TextLink,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { memo, useCallback, useState } from 'react';
import styles from './MailNotificationSetting.module.scss';
import { flagshipUrl, formId } from './consts';
import { useSubmit } from './hooks/useSubmit';

const cx = classnames.bind(styles);

export const MailNotificationSetting = memo(() => {
  const { data, refetch } = useNotificationSettingInfoSuspense();
  const [isEdit, setIsEdit] = useState(false);
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const [{ setError }, ErrorContextHolder, ErrorInlineNotificationProvider] =
    useErrorInlineNotification();
  const callback = useCallback(() => {
    setIsEdit(false);
    setVisible(true);
    refetch();
  }, [refetch]);
  const onSubmit = useSubmit(setError, callback);
  const toggleEditMode = useCallback(() => {
    setIsEdit((prevIsEdit) => !prevIsEdit);
  }, [setIsEdit]);
  const closeNotification = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <>
      <ErrorInlineNotificationProvider value={setError}>
        <div className={cx(styles['inline-notification-message'])}>
          <InlineNotification
            message={t('update_mail_notification_setting_success_message')}
            onClick={closeNotification}
            type='success'
            visible={visible}
            icon={<IconApproval size={18} />}
          />
          {ErrorContextHolder ? ErrorContextHolder : null}
        </div>
        <div className={cx(styles['title-container'])}>
          <Heading as='h2' className={cx(styles['section-title'])}>
            {t('mail_notification')}
          </Heading>
          {isEdit ? (
            <ButtonGroup direction='horizontal'>
              <ButtonV2 color='primary' isSecondary onClick={toggleEditMode}>
                {t('cancel')}
              </ButtonV2>
              <ButtonV2 color='primary' form={formId} type='submit'>
                {t('save')}
              </ButtonV2>
            </ButtonGroup>
          ) : (
            <ButtonV2 color='primary' isSecondary onClick={toggleEditMode}>
              {t('edit')}
            </ButtonV2>
          )}
        </div>
        {isEdit ? (
          <MailNotificationSettingForm data={data} onSubmit={onSubmit} />
        ) : (
          <MailNotificationSettingInfo data={data} />
        )}
        <div className={cx(styles['notice-description'])}>
          {t('flagship_announce')}
          <TextLink href={flagshipUrl} target='_blank'>
            {t('flagship_link_text')}
          </TextLink>
        </div>
      </ErrorInlineNotificationProvider>
    </>
  );
});

MailNotificationSetting.displayName = 'MailNotificationSetting';
