import { CurrencyRateDeleteModal } from '@/features/foreign_currency_settings/Detail/CurrencyRateDeleteModal';
import { useColumns } from '@/features/foreign_currency_settings/components/Detail/CurrencyRateTable/Columns/columns';
import { useDeleteCurrencyRate } from '@/features/foreign_currency_settings/components/Detail/CurrencyRateTable/hooks/useDeleteCurrencyRate';
import { useTranslation } from '@/i18n';
import {
  ButtonV2,
  ModernPaginationProps,
  Table,
} from '@moneyforward/ap-frontend-components';
import { FC, memo, useCallback, useState } from 'react';
import { DataTableCurrencyRate } from './type';

export type Props = {
  currency: string | undefined;
  data: DataTableCurrencyRate[];
  onDeleteCallback: () => void;
  pagination: ModernPaginationProps;
  isLoading: boolean;
};

export const CurrencyRateTable: FC<Props> = memo(
  ({ data, onDeleteCallback, pagination, isLoading }) => {
    const { t } = useTranslation();
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [selectedItem, setSelectedItem] =
      useState<DataTableCurrencyRate | null>(null);
    const [isDisabled] = useState(false);

    const showDrawer = useCallback(() => {
      // console.log('show drawer');
    }, []);

    const onCloseDeleteModal = useCallback(() => {
      setIsOpenDeleteModal(false);
    }, []);

    const onClickShowDeleteModal = useCallback(
      (value: DataTableCurrencyRate) => {
        setIsOpenDeleteModal(true);
        setSelectedItem(value);
      },
      []
    );

    const onClickEdit = useCallback(() => {
      showDrawer();
    }, [showDrawer]);

    const onDeleteCurrencyRate = useDeleteCurrencyRate(onDeleteCallback);

    const onDeleteItem = useCallback(async () => {
      if (selectedItem) {
        await onDeleteCurrencyRate(selectedItem.id);
      }
      onCloseDeleteModal();
    }, [onCloseDeleteModal, onDeleteCurrencyRate, selectedItem]);

    const columns = useColumns(onClickEdit, onClickShowDeleteModal, isDisabled);

    return (
      <>
        <Table
          columns={columns}
          data={data}
          legacy={false}
          emptyType='object'
          pagination={pagination}
          hideFooterPagination
          loading={isLoading}
          tableAction={
            <ButtonV2 isSecondary isDisabled={isDisabled} onClick={showDrawer}>
              {t('add')}
            </ButtonV2>
          }
        />
        <CurrencyRateDeleteModal
          title={t('foreign_currency.detail.modal.delete.title')}
          open={isOpenDeleteModal}
          onClose={onCloseDeleteModal}
          onDelete={onDeleteItem}
        />
        {/* side modal */}
      </>
    );
  }
);
CurrencyRateTable.displayName = 'CurrencyRateTable';
