import { useErrorInlineNotificationAPI } from '@/components/ErrorInlineNotification/ErrorInlineNotification';
import { isSuccess } from '@/context/services/type';
import { useTranslation } from '@/i18n';
import { useExportApCurrencyRates } from 'ap-openapi';

export const useExportCurrencyRates = () => {
  const { t } = useTranslation();
  const { mutateAsync, error } = useExportApCurrencyRates();
  const setErrorNotification = useErrorInlineNotificationAPI();

  if (error) throw error;

  return async (csvEncode: string) => {
    try {
      const results = await mutateAsync({ params: { csv_encode: csvEncode } });
      if (isSuccess(results.status)) {
        // download csv file
        // fileName: ap_currency_rates_YYYY-MM-DD.csv
      } else {
        throw error ? error : results.data;
      }
    } catch (error) {
      setErrorNotification(
        new Error(t('foreign_currency.notification.export.error'))
      );
    }
  };
};
