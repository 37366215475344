import { useConvertToModernPagination } from '@/context/services/hooks/usePagination';
import { CurrencyRatesColumn } from '@/features/foreign_currency_settings/components/List/type';
import { ModernPaginationProps } from '@moneyforward/ap-frontend-components';
import {
  ApCurrencyRate,
  CommonError,
  GetApCurrencyRatesParams,
  useGetApCurrencyRatesSuspense,
} from 'ap-openapi';
import { Dayjs } from 'dayjs';
import { useMemo } from 'react';

export type CurrencyRateValue = {
  currency: string;
  date: string[] | Dayjs[];
  value: number;
};

export type FetchCurrencyRateHooks = (
  args: GetApCurrencyRatesParams
) => FetchCurrencyRateResult;

export type FetchCurrencyRateResult = {
  pagination: ModernPaginationProps;
  data: CurrencyRatesColumn[];
  refetch: CurrencyRateRefetch;
  error: CommonError | null;
  isLoading: boolean;
};

export type CurrencyRateRefetch = ReturnType<
  typeof useGetApCurrencyRatesSuspense
>['refetch'];

export type CurrencyRateQuery = GetApCurrencyRatesParams;

export const useCurrencyRateSearch: FetchCurrencyRateHooks = (args) => {
  const { page = 1, per = 25, currency, date } = args;

  const { data, error, refetch, isLoading } = useGetApCurrencyRatesSuspense({
    page,
    per,
    currency,
    date,
  });

  const pagination: ModernPaginationProps = useConvertToModernPagination(
    data?.data?.pagination
  );

  const result = useMemo(
    () => data?.data.ap_currency_rates?.map(responseToRateSettingColumn) ?? [],
    [data]
  );
  return {
    pagination,
    data: result,
    refetch,
    error,
    isLoading,
  };
};

const responseToRateSettingColumn = (
  item: ApCurrencyRate
): CurrencyRatesColumn => {
  return {
    id: item.id,
    currency: item.currency,
    value: item.value,
    start_date: item.start_date,
    updated_at: item.updated_at,
    end_date: item.end_date,
  } satisfies CurrencyRatesColumn;
};
