import type { MailNotificationSettingFormType } from '@/context/services/notification_setting/type';
import { useForm } from 'react-hook-form';

export const useMailNotificationForm = (
  defaultValue: MailNotificationSettingFormType
) => {
  const methods = useForm<MailNotificationSettingFormType>({
    mode: 'all',
    defaultValues: defaultValue,
  });

  return methods;
};
