import {
  useSuspenseNotificationSettingQuery,
  useUpdateNotificationSettingMutation,
} from 'ap-graphql';
import { useMemo } from 'react';
import {
  MailNotificationSettingFormType,
  MailNotificationSettingRequest,
} from './type';

export const useNotificationSettingInfoSuspense = () => {
  const { data, error, refetch } = useSuspenseNotificationSettingQuery();
  if (error) throw error;
  if (!data.currentOfficeMember) throw Error;
  const { approvableReport, workflow, reportTimeline } =
    data.currentOfficeMember.notificationSettings.mail;
  return useMemo(
    () => ({
      data: {
        approvableReport: approvableReport.isActive,
        workflow: workflowEnum(workflow),
        reportTimeline: reportTimeline.isActive,
      },
      refetch: refetch,
    }),
    [approvableReport, workflow, reportTimeline, refetch]
  );
};

export const useUpdateNotificationSetting = () => {
  return useUpdateNotificationSettingMutation();
};

const workflowEnum = (
  workflow: MailNotificationSettingRequest['workflow']
): MailNotificationSettingFormType['workflow'] => {
  if (!workflow.isActive) return 'not_notify';
  if (workflow.onlyFinalApproval) return 'only_final_approval';
  return 'every_step';
};
