import { useAgentApplicantResourceHeaders } from '@/context/AgentApplicantOfficeMember';
import {
  useDownloadHook,
  useDownloadS3SignedUrlSuspense,
} from '@/context/services/downloads/index.service';
import { SecondParameter } from '@/context/services/type';
import { UseSuspenseQueryOptions } from '@tanstack/react-query';
import {
  BadRequestResponse,
  ErrorType,
  ForbiddenResponse,
  NotFoundResponse,
  UnauthorizedResponse,
  UnprocessableEntityResponse,
  useGetMfFile as useBaseGetMfFile,
  useCustomClient,
  useGetMfFileHook,
  useGetMfFilePdfPreviewSuspense,
  useGetMfFilePreviewUrlHook,
  useGetMfFilePreviewUrlSuspense,
  useGetMfFileSuspense,
} from 'ap-openapi';
import { useMemo } from 'react';

const dummyBlob = new Blob([]);

export const useGetMfFile = (
  mfFileId: string = '',
  enabled: boolean = true
) => {
  const getMfFile = useGetMfFileHook();
  const headers = useAgentApplicantResourceHeaders('mf_file');
  const { data, error } = useGetMfFileSuspense(mfFileId, {
    query: {
      // @ts-ignore
      queryFn: ({ signal }) =>
        enabled ? getMfFile(mfFileId, {}, signal) : null,
    },
    request: {
      headers,
    },
  });
  if (error) throw error;
  return useMemo(() => (enabled ? data.data : dummyBlob), [data, enabled]);
};

export const useGetMfFilePreview = (mfFileId: string = '') => {
  const headers = useAgentApplicantResourceHeaders('mf_file');
  const { data, error } = useGetMfFilePdfPreviewSuspense(mfFileId, {
    request: {
      headers,
    },
  });
  if (error) throw error;
  if (data.status === 404) {
    return undefined;
  }
  return data.data;
};

export const useGetLazyMfFile = (mfFileId: string = '') => {
  const headers = useAgentApplicantResourceHeaders('mf_file');
  const { data, error, isFetching, isLoading, isRefetching } = useBaseGetMfFile(
    mfFileId,
    {
      query: {
        enabled: Boolean(mfFileId),
      },
      request: {
        headers,
      },
    }
  );
  return useMemo(
    () => ({
      data: data?.data,
      error,
      isFetching: [isFetching, isLoading, isRefetching].some((v) => v),
    }),
    [data?.data, error, isFetching, isLoading, isRefetching]
  );
};

export const usePreviewUrl = (mfFileId: string, enabled: boolean = false) => {
  type TData = Awaited<
    ReturnType<ReturnType<typeof useGetMfFilePreviewUrlHook>>
  >;
  type TError = ErrorType<
    | BadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | NotFoundResponse
    | UnprocessableEntityResponse
  >;

  const getMfFilePreviewUrl = useGetMfFilePreviewUrlHook();
  const headers = useAgentApplicantResourceHeaders('mf_file');
  // @ts-ignore
  const queryOptions: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetMfFilePreviewUrlHook>>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<ReturnType<typeof useCustomClient>>;
  } = useMemo(
    () => ({
      query: {
        // @ts-ignore
        queryFn: ({ signal }) =>
          enabled ? getMfFilePreviewUrl(mfFileId, {}, signal) : null,
      },
      request: {
        headers,
      },
    }),
    [enabled, getMfFilePreviewUrl, headers, mfFileId]
  );
  const { data, error } = useGetMfFilePreviewUrlSuspense(
    mfFileId,
    queryOptions
  );
  if (error) throw error;
  return useMemo(() => (enabled ? data.data.signed_url : ''), [data, enabled]);
};

export const useDownloadS3SignedUrl = (
  signedUrl: string,
  enabled: boolean = false
) => {
  const downloadFn = useDownloadHook();

  const { data, error } = useDownloadS3SignedUrlSuspense(signedUrl, {
    query: {
      // @ts-ignore
      queryFn: ({ signal }) => (enabled ? downloadFn(signedUrl, signal) : null),
    },
  });

  if (error) throw error;
  return useMemo(() => {
    return enabled ? data?.data || dummyBlob : dummyBlob;
  }, [data?.data, enabled]);
};

export const useGetMfFileAndObjectURL = (mfFileId: string = '') => {
  const headers = useAgentApplicantResourceHeaders('mf_file');
  const { data, error } = useGetMfFilePreviewUrlSuspense(mfFileId, {
    request: { headers },
  });
  if (error) {
    throw error;
  }
  const signedUrl = useMemo(() => data.data.signed_url, [data.data.signed_url]);
  const { data: s3Data, error: s3Error } =
    useDownloadS3SignedUrlSuspense(signedUrl);
  if (s3Error) {
    throw error;
  }
  const downloadBlob = useMemo(() => s3Data.data || dummyBlob, [s3Data.data]);
  return {
    originalBlob: downloadBlob,
    url: signedUrl,
  };
};
