import { MailNotificationSetting } from '@/features/notification_setting/components/MailNotificationSetting';
import { useTranslation } from '@/i18n';
import { Layout } from '@/Layout';

export const NotificationSetting = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('notification')}>
      <MailNotificationSetting />
    </Layout>
  );
};
