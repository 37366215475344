import { FC, memo } from 'react';

export const IconMinimize: FC = memo(() => (
  <svg
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='icon/minimize'>
      <path
        id='Vector'
        d='M4.42383 14.0003V12.667H12.4238V14.0003H4.42383Z'
        fill='#C6C6C6'
      />
    </g>
  </svg>
));
IconMinimize.displayName = 'IconMinimize';
