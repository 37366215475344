import { useErrorInlineNotification } from '@/components/ErrorInlineNotification';
import { useGetForeignCurrencyRates } from '@/context/services/foreign_currency/Detail/ApCurrencyRates.service';
import { CurrencyRateTable } from '@/features/foreign_currency_settings/components/Detail/CurrencyRateTable';
import { DataTableCurrencyRate } from '@/features/foreign_currency_settings/components/Detail/CurrencyRateTable/type';
import { useTranslation } from '@/i18n';
import { Heading } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { memo, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from '../foreignCurrencySettings.module.scss';

const cx = classnames.bind(styles);

export const ForeignCurrencySettingsDetail = memo(() => {
  const { currency } = useParams();

  const { t } = useTranslation();
  const [query, setQuery] = useState({ page: 1 });
  const [
    { setError },
    ErrorContextHolder,
    ErrorInlineNotificationProvider,
    // onClearMessage,
  ] = useErrorInlineNotification();

  const onPageChange = useCallback(
    (page: number) => {
      setQuery({ ...query, page });
    },
    [query]
  );

  const {
    data: apCurrencyRates,
    refetch,
    pagination,
    isLoading,
  } = useGetForeignCurrencyRates({
    currency,
    page: query.page,
    onPageChange,
  });

  const dataTableCurrencyRates: DataTableCurrencyRate[] =
    apCurrencyRates?.map((rate) => ({
      id: rate.id ?? '',
      rate: rate.value ?? 0,
      period: `${rate.start_date} 〜 ${rate.end_date}`,
      updated_at: rate.updated_at ?? '',
    })) ?? [];

  const onDeleteCallback = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <ErrorInlineNotificationProvider value={setError}>
      <div className={cx(styles['container'])}>
        <div className={cx(styles['inline-notification-wrap'])}>
          {ErrorContextHolder}
        </div>
        <Heading as='h3' className={cx(styles['page-heading'])}>
          {t('foreign_currency_settings.detail.title')}
        </Heading>
        <CurrencyRateTable
          currency={currency}
          data={dataTableCurrencyRates}
          pagination={pagination}
          onDeleteCallback={onDeleteCallback}
          isLoading={isLoading}
        />
      </div>
    </ErrorInlineNotificationProvider>
  );
});

ForeignCurrencySettingsDetail.displayName = 'ForeignCurrencySettingsDetail';
