import { MailNotificationSettingFormType } from '@/context/services/notification_setting//type';
import { useUpdateNotificationSetting } from '@/context/services/notification_setting/notification_setting.service';
import { useTranslation } from '@/i18n';
import { useCallback } from 'react';

export const useSubmit = (
  setError: (error: Error) => void,
  _onCallback: () => void | undefined
) => {
  const { t } = useTranslation();
  const { mutateAsync } = useUpdateNotificationSetting();
  return useCallback(
    async (data: MailNotificationSettingFormType) => {
      try {
        const mailNotificationSetting = {
          updateNotificationSettingsInput: {
            mail: {
              approvableReport: { isActive: data.approvableReport },
              workflow: workflowRequestValue(data.workflow),
              reportTimeline: { isActive: data.reportTimeline },
            },
          },
        };
        const res = await mutateAsync(mailNotificationSetting);
        if (res) {
          if (_onCallback) {
            _onCallback();
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          error.message = t('updated_mail_notification_setting_error_message');
          setError(error);
        }
      }
    },
    [_onCallback, setError, t, mutateAsync]
  );
};

const workflowRequestValue = (
  workflow: 'every_step' | 'only_final_approval' | 'not_notify'
) => {
  if (workflow === 'only_final_approval')
    return { isActive: true, onlyFinalApproval: true };
  if (workflow === 'every_step')
    return { isActive: true, onlyFinalApproval: false };
  if (workflow === 'not_notify')
    return { isActive: false, onlyFinalApproval: false };

  throw new Error();
};
