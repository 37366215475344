import { useConvertToModernPagination } from '@/context/services/hooks/usePagination';
import { ModernPaginationProps } from '@moneyforward/ap-frontend-components';
import { useGetApCurrencyRatesSuspense } from 'ap-openapi';

type Props = {
  currency: string | undefined;
  page?: number;
  perPage?: number;
  onPageChange: (value: number) => void;
};

export const useGetForeignCurrencyRates = (args: Props) => {
  const { page = 1, perPage = 25, onPageChange, currency } = args;
  const { data, error, refetch, isLoading } = useGetApCurrencyRatesSuspense({
    currency: currency,
    per: perPage,
    page: page,
  });

  if (error) throw error;

  const pagination: ModernPaginationProps = useConvertToModernPagination(
    data?.data?.pagination,
    onPageChange
  );

  return {
    data: data?.data.ap_currency_rates,
    pagination,
    refetch,
    error,
    isLoading,
  };
};
