import { useErrorInlineNotificationAPI } from '@/components/ErrorInlineNotification/ErrorInlineNotification';
import { isSuccess } from '@/context/services/type';
import { useTranslation } from '@/i18n';
import { useNotification } from '@moneyforward/ap-frontend-components';
import { useQueryClient } from '@tanstack/react-query';
import {
  getGetApCurrencyRatesQueryKey,
  useDeleteApCurrencyRateByCurrencyAndDate,
} from 'ap-openapi';
import { useCallback } from 'react';

type Props = {
  onClearMessage: () => void;
};

export const useDeleteCurrency = ({ onClearMessage }: Props) => {
  const { t } = useTranslation();
  //TODO: Check again when toaster is merged
  const query = useQueryClient();
  const notify = useNotification();
  const setErrorNotification = useErrorInlineNotificationAPI();
  const { mutateAsync: _mutateAsync, error } =
    useDeleteApCurrencyRateByCurrencyAndDate({
      mutation: {
        onSuccess: () => {
          query.invalidateQueries({
            queryKey: getGetApCurrencyRatesQueryKey(),
          });
        },
      },
    });

  return useCallback(
    async (currency: string) => {
      try {
        const resp = await _mutateAsync({
          currency,
        });
        if (isSuccess(resp.status)) {
          onClearMessage();
          notify.success({
            title: t('foreign_currency.currency.delete.success'),
            opts: {
              duration: 3000,
              style: {
                position: 'fixed',
              },
            },
            bodyProps: {
              disabledSuffix: true,
            },
          });
        } else {
          throw error ? error : resp.data;
        }
      } catch (error) {
        setErrorNotification(new Error(t('system_error')));
      }
    },
    [_mutateAsync, error, notify, onClearMessage, setErrorNotification, t]
  );
};
